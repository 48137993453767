<template>
  <div class="page-thank-you">
    <div class="container">
      <h1 class="thank-you__title">Vielen Dank für die Anfrage!</h1>
      <p class="thank-you__body">
        Die Fachleute werden sich so schnell wie möglich mit Ihnen in Verbindung
        setzen.
      </p>
    </div>

    <div class="container">
      <div id="usps" class="usps__container">
        <h2 class="usps__title">Tipps zum Durchgehen</h2>
        <Usps :usps="usps" />
      </div>
    </div>
  </div>
</template>

<script>
import Usps from 'chimera/all/themes/blueflow/components/Usps'
import AbstractPage from 'chimera/all/components/page/AbstractPage'

export default {
  components: {
    Usps,
  },

  extends: AbstractPage,

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Bewerbung abgeschlossen',
      headDescription:
        'Geben Sie Ihre Postleitzahl ein und vergleichen Sie kostenlos 6 Angebote von Top-Unternehmen in Ihrer Region. ✓Der beste Preis für Ihren Solarmodullieferanten ✓Sparen Sie bis zu 40% ✓100% kostenlos!',
      path: '/angebotsanfragen/bewerbung-abgeschlossen',
      usps: [
        {
          text: 'Stellen Sie sicher, dass Sie Ihr Telefon und Ihren Terminkalender griffbereit haben, denn Profis können Sie telefonisch erreichen.',
          logo: require('chimera/all/themes/blueflow/images/usps/calendar.svg'),
        },
        {
          text: 'Schauen Sie nicht nur auf den Preis, sondern vergleichen Sie auch Qualität, Service und Zuverlässigkeit.',
          logo: require('chimera/all/themes/blueflow/images/usps/offer.svg'),
        },
        {
          text: 'Nehmen Sie sich Zeit, um den richtigen Fachmann zu wählen.',
          logo: require('chimera/all/themes/blueflow/images/usps/safety.svg'),
        },
      ],
    }
  },
}
</script>
